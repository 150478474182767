.dashboard {
  margin: 0 10px;
  color: #ffffff;

  .dashboardHeader {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    // min-height: 70px;
    margin-bottom: 10px;
    white-space: nowrap;
    margin-right: 11px;
    margin-left: 11px;

    .dashboardReserved {
      width: 100%;
    }

    .dashboardCorporation {
      text-align: right;
      font-size: 0.9rem;
      display: flex;
      align-items: flex-end;
    }

  }



  .page-body {
    overflow: auto;
    max-height: calc(100vh - 120px);
  }

  .dashboard-sweet {
    color: #ffffff;

    .container {
      margin: 0;
      min-width: 100%;
    }

    .page-sweet {
      .picker-button {
        background-color: transparent;
        border: none;
      }
    }
  }

  .climate-partials {

    .climate-current {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.09);

      .climate-current-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .climate-current-icon {
          max-width: 65px;
          max-height: 65px;
        }

        .current-main {
          display: flex;

          .climate-current-temperature {
            font-size: 2rem;

            span {
              font-size: .8rem;
            }
          }

          .climate-current-label {
            line-height: .8rem;
            font-size: 1rem;
          }
        }
      }

      .climate-current-information {
        display: flex;
        justify-content: space-between;
        color: #ababab;
        text-align: center;
        font-size: 0.8rem;

        .climate-current-temperature {
          padding: 0.2rem;
        }
      }
    }

    .climate-hour {
      display: flex;
      justify-content: space-between;

      .card-hour {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(255, 255, 255, 0.09);
        border-radius: 5px;
        color: #ababab;
        font-size: 0.8rem;
        margin: 0 2px;
        justify-content: center;

        .climate-current-icon {
          max-width: 55px;
          max-height: 55px;
        }
      }
    }
  }

  .card-timer {
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;

    .row {
      justify-content: center;
      font-weight: bold;

      input {
        background-color: transparent;
        border: none;
        text-align: center;
        color: white;
      }
    }
  }

  .grafics {
    padding: 5px;
    color: white;
    border: 1px solid #6c757d;
    border-radius: 0.25rem;

    height: 250px;
  }

  .card {
    background: rgba(255, 255, 255, 0.055);
    backdrop-filter: blur(13.5px);
    color: white;

    .card-img,
    .card-img-top,
    .card-img-bottom {
      max-width: 100%;
      height: auto;
    }

    @media all and (-ms-high-contrast: none) {

      .card-img,
      .card-img-top,
      .card-img-bottom {
        height: 100%;
      }
    }

    .card-actions {
      display: flex;

      #form-date-start {
        background-color: transparent;
        border: none;
        color: white;
      }
    }

    .card-actions .input-group {
      margin: 0 5px;
    }

    .card-actions input[type='date'] {
      max-width: 140px;
    }

    .card-actions input[type='text'] {
      max-width: 90px;
    }

    .card-actions a {
      text-decoration: none;
    }

    .card-actions svg {
      width: 16px;
      height: 16px;
    }

    .card-actions .dropdown {
      line-height: 1.4;
    }

    .card-img-hover {
      transition: all .1s ease-in-out;
    }

    .card-img-hover:hover {
      transform: scale(1.035);
    }

  }

  .movement-resume {
    display: flex;
    height: 100%;

    .movement-resume-item {
      width: 100%;
      padding: 5px;
      margin: 0 5px;
      border: 1px solid rgba(255, 255, 255, 0.09);
      border-radius: 0.25rem;

      .movement-resume-img {
        text-align: center;
        margin-top: 7px;
      }

      .movement-resume-label {
        text-align: center;
        font-weight: bold;
      }

      .movement-resume-data {
        font-size: 1.6rem;
        color: #ffffff;
        font-weight: bold;
        text-align: center;
        border-radius: 0.25rem;
        margin-top: 15px;
      }
    }
  }

  //@media (max-width: 991.98px)  {
  @media (max-width: 1360px) {
    .dashboard-header {
      flex-direction: column;
    }

    .movement-resume-label {
      display: none;
    }

    .movement-resume-data {
      margin: 0.2rem 0;
    }

    .climate-current {
      flex-direction: column;
      margin-top: 0.5rem;

      .climate-current-main {
        flex-direction: row !important;
        padding-bottom: 0.2rem;

        .climate-current-information {
          flex-direction: column;
          text-align: flex-end;
        }
      }
    }

    .card-timer {
      display: initial;
      padding: 0.2rem 0;
    }

    .card-header {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
  }

}

.mainInfo {
  border-bottom: solid 1px #FFFFFF;
  padding: 5px 0 15px 0;
  margin: 0 5px;

  .clockTitle {
   min-height: 125px;

   .partnerLogo {
    vertical-align: middle;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
   }

   .partnerLogoPlaceholder {
    vertical-align: middle;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    border: solid 1px #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin-bottom: 0;
    }
   }

   h1 {
    font-size: 4.5rem;
    min-width: 475px;
   }
   h2 {
    font-size: 2rem;
   }
  }
 
}

.logoPartner {
  position: fixed;
  width: 120px;
  right: 15px;
  bottom: 15px;
}



.table {

  th, tr, td, div {
    font-size: 105%;
    font-weight: bolder;

    &:not(th) {
      border: none;
    }


  }

  tr:nth-child(even) {
    background-color:darken(#586A88, 10%);
  }
  thead {
    text-transform: uppercase;
    font-weight: 800;
  }

  margin-bottom: 0;
}

.vesselColor {
  display: block;
  width: 30px;
  height: 10px;
  border-radius: 0.25rem;
}


@media (max-width: 1360px) {

  .clockTitle {

    h1 {
      font-size: 3.5rem !important;
      min-width: 220px !important;
      margin-right: 0 !important;

    }

    h2 {
      font-size: 1.5rem !important;
     }
  }

}
.switch {
    position: absolute;
    right: 220px;
    top: 16px;
}