.dropdownToogle {
  .selectedColor {
    width: 100%;
    min-width: 15px;
    height: 20px;
    border-radius: 0.25rem;

  }

  &::after {
    display: none !important;
  }
}

.dropdownMenu {
  width: 3px !important;
  min-width: 36px;

  .dropdownItem {
    padding: 5px 6px !important;
    padding-right: 2px !important;
    width: fit-content;
    margin: 0 !important;
  }
}

.vesselSchedules {
  padding: 5px;
  background-color: var(--secondary);

  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  ;

  .vesselSchedule {
    margin: 2px 0 4px;
    padding: 4px 6px;

    background-color: #FAFAFA;
    color: #000000;
    border-radius: 0.25rem;

    .vesselScheduleHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      font-weight: 700;
      border-bottom: 1px solid var(--secondary);
      margin-bottom: 10px;

      .vesselDate {
        padding: 0 6px;
        background-color: #ffffff;
        border-radius: 0.25rem;
      }
    }
  }
}

.imageWrapper {
  transition: all ease-in-out .5s;
  display: flex;
  justify-content: center;
  height: 180px;

  img {
    width: 100%;
    max-height: 170px;
    object-fit: contain;
    margin: auto;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

  }
  &.vesselImage {
    height: 100px;
  }
  &.vesselImagePlaceholder {
    min-height: 100px;
    min-width: 108px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;

    .svg {
      margin-top: 23px;
      border: solid 3px #DADADA;
      width: 100%;
      border-radius: 4px;
      display: flex;
      padding: 15px;
      justify-content: center;
      background-color: #DADADA;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

    }

  }

}

.imageControls {
  label {
    margin: 0;
    padding: 5px;
    width: fit-content;
  }
}

.switch {
  font-size: 22px;

  input {
    &:hover {
      cursor: pointer;
    }
  }
  label {
    &:hover {
      cursor: pointer;
    }
  }

}