.imageWrapper {
  transition: all ease-in-out .5s;
  display: flex;
  justify-content: center;
  height: 100px;
  width: 80%;


  img {
    max-height: 180px;
    min-height: 180px;
    object-fit: contain;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 0;
    margin: 0

  }
  &.vesselImage {
    height: 180px;
  }
  &.vesselImagePlaceholder {
    min-height: 210px;
    height: 210px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .svg {
      margin-top: 23px;
      border: solid 3px #DADADA;
      width: 80%;
      height: 80%;
      border-radius: 4px;
      display: flex;
      padding: 15px;
      justify-content: center;
      background-color: #DADADA;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

  }

}

.imageControls {
  label {
    margin: 0;
    padding: 5px;
    width: fit-content;
  }
}