body {
  background: var(--background-color);
}

.container {
  max-width: 905px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  input:-webkit-autofill {
    -webkit-text-fill-color: #000!important;
    box-shadow: inset 0 0 0 1000px #fff!important;
    -webkit-tap-highlight-color: white;
    background-color: #fff!important;
  }

  a {
    display: inline-block;
    text-decoration: none;
  }


  h1 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
    font-weight: 700;
  }

  .formHolder {
    padding: 50px 0;
    width: 100%;
  }

  .brand {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-size: cover;
    background-position: center center;
    color: #fff;
    min-height: 660px;
    position: relative;
    right: 1px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.6s cubic-bezier(1, -0.375, 0.285, 0.995);
    z-index: 5;

    .logo {
      max-width: 450px;
    }
  }

  .brand.active {
    width: 100%;
  }

  .brand::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000000;
    z-index: -1;
  }

  .form {
    position: relative;
  }

  .form .formPeice {
    background: #fff;
    min-height: 600px;
    margin-top: 30px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    color: #bbbbbb;
    padding: 30px 0 60px;
    transition: all 0.9s cubic-bezier(1, -0.375, 0.285, 0.995);
    position: absolute;
    top: 0;
    left: -30%;
    width: 135%;
    overflow: hidden;
  }

  .form .formPeice.switched {
    transform: translateX(-100%);
    width: 0;
    left: 1;
  }

  .form form {
    padding: 0 20px;
    margin: 0;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
  }

  .form form .formGroup {
    margin-bottom: 5px;
    position: relative;
  }

  .form form label.fontSwitch {
    font-family: "Raleway", sans-serif !important;
    font-weight: 600;
  }

  .form form span.error {
    color: #f95959;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    right: 0;
    display: none;
  }


  .form form .CTA {
    margin-top: 30px;
  }

  .form form .CTA a.switch {
    font-size: 13px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    color: #bbbbbb;
    text-decoration: underline;
    transition: all 0.3s;
  }

  .form form .CTA a.switch:hover {
    color: #f95959;
  }

}

@media (max-width: 768px) {

  .container {
    overflow-x: hidden;
  }

  .formHolder {
    padding: 0;
  }

  .brand {
    min-height: 200px !important;
    max-height: 250px;
    width: max-content;
    left: 0;

  }
  .formHolder div.brand.active {
    min-height: 80vh !important;
  }

  .formHolder div.brand .heading.active {
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .formHolder .form {
    width: 80vw;
    height: 560px;
    margin-bottom: 100px;
  }

  .formHolder .form .formPeice {
    margin: 0;
    top: 0;
    left: 0;
    width: 100% !important;
    transition: all 0.5s ease-in-out;
    box-shadow: none;
    overflow: scroll;
  }

  .formHolder .form .formPeice.switched {
    transform: translateY(-100%);
    width: 100%;
    left: 0;
  }

  .formHolder .form .formPeice>form {
    width: 100% !important;
    padding: 25px;
    left: 50%;
  }
  .accessControls {
    flex-direction: column;
    padding-bottom: 15px;

    button {
      margin-left: 0;
    }
  }
}

@media (max-width: 480px) {
  .formHolder .form {
    width: 100vw;
    margin-left: 0;
    padding-bottom: 15px;
  }

  h2 {
    font-size: 50px !important;
  }

  .accessControls {
    flex-direction: column;

    button {
      margin-left: 0;
    }
  }
}



@media (max-width: 1370px) {

  .container {
    overflow: hidden;
  }
}