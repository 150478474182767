    @import '~bootstrap/scss/bootstrap-grid';
    @import '~bootstrap/scss/bootstrap-reboot';
    @import '~bootstrap/scss/bootstrap-utilities';
    @import 'reset';
    @import 'fonts';
    @import 'variables';

    *,
    *::before,
    *::after {
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        font-size: .9rem;
        text-rendering: optimizeLegibility;
    }

    :root {
        --color-background: var(--background-color)
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    a:hover {
        color: inherit;
        text-decoration: underline;
    }

    p {
        margin: 0;
    }

    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }


    ol,
    ul,
    dl {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
        margin-bottom: 0;
    }

    ul>li {
        list-style-type: none;
    }

    fieldset {
        position: relative;
        display: block;
        padding: 5px 5px 10px;
        margin-bottom: 0.6rem;
        background: #6E747F;
        border-radius: 0.3rem;
    }

    @media (max-width: 991.98px) {
        fieldset {
            overflow: auto;
        }
    }

    legend {
        color: white;
        padding: 0 5px;
        font-size: 0.9rem;
        font-weight: bold;

        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    [class*="col-"] {
        padding: 0;
        margin: 0;
    }

    .btn-primary {
        margin: 0 0.1rem;
    }

    .btn-secondary {
        padding: 2px 5px 5px !important;
    }

    .btn svg {
        margin: 0 5px;
        margin-bottom: 3px;
    }

    .paragraph {
        display: block;
        margin-block-start: 0.3em;
        margin-block-end: 0.3em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        text-indent: 1.5rem;
    }

    .overflow-x-auto {
        overflow-x: auto;
    }

    .overflow-scroll {
        width: 100%;
        overflow: scroll;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .overflow-auto {
        width: 100%;
        overflow: auto;
    }

    .overflow-visible {
        overflow: visible;
    }

    .position-static {
        position: static !important;
    }

    .position-relative {
        position: relative !important;
    }

    .position-absolute {
        position: absolute !important;
    }

    .position-fixed {
        position: fixed !important;
    }

    .position-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }

    .fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
    }

    .fixed-bottom {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
    }

    .float-left {
        float: left !important;
    }

    .float-right {
        float: right !important;
    }

    .float-none {
        float: none !important;
    }

    .text-dark {
        color: #153d77 !important;
    }

    a.text-dark:hover,
    a.text-dark:focus {
        color: #091c36 !important;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }

    .no-gutters {
        margin-right: 0;
        margin-left: 0;
    }

    .no-gutters>.col,
    .no-gutters>[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    .align-items-center {
        align-items: center !important;
    }

    .font-weight-bold {
        font-weight: bold;
    }

    .img-fluid {
        max-width: 100%;
        height: 100px;
    }

    .img-thumbnail {
        padding: 0.25rem;
        background-color: #f4f7fc;
        border: 1px solid #e6f0fa;
        border-radius: 0.2rem;
        max-width: 100%;
        height: auto;
    }


    .rounded-lg {
        border-radius: 0.3rem !important;
    }

    .rounded-circle {
        border-radius: 50% !important;
        max-width: 100px;
        height: auto;
    }

    .rounded-pill {
        border-radius: 50rem !important;
    }


    .avatar {
        width: 40px;
        height: 40px;
    }

    .avatar-title {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .climate {
        color: #ffffff;
        font-size: 0.75rem;
        margin: auto;

        align-items: center;
        text-align: center;

        .current {
            display: flex;

            .current-data {
                display: flex;
                vertical-align: middle;

                .current-temperature {
                    font-size: 2.2rem;
                    white-space: nowrap;
                }

                .current-degrees {
                    margin-top: 9px;
                    margin-left: 6px;

                    vertical-align: top;
                    font-size: 1rem;
                }
            }

            .current-other {
                padding-top: 3px;
                margin-left: 10px;
                color: #ababab;
                text-align: center;
                white-space: nowrap;

                span {
                    display: block;
                    margin-bottom: -2px;
                    white-space: nowrap;
                }
            }

            .current-text {
                padding: 5px 20px;
                font-size: 1.7rem;
                white-space: nowrap;
            }
        }


        .forecast {
            padding: 10px;

            .forecasttoday {
                background-color: #fff;
                color: #154370;

                .forecasttoday-datail-day {
                    display: flex;
                    justify-content: space-between;

                    border-bottom: 1px solid #154370;
                    padding-bottom: 5px;
                    font-size: 1.2rem;

                    .forecasttoday-datail-day-left,
                    .forecasttoday-datail-day-center,
                    .forecasttoday-datail-day-right {
                        span {
                            display: block;
                        }
                    }

                    .forecasttoday-datail-day-center {
                        span:last-child {
                            font-size: 1.5rem;
                        }
                    }

                    .forecasttoday-datail-day-right {
                        font-size: 1rem;
                        margin: 0;
                        padding: 0;
                    }
                }

                .forecasttoday-datail-hour {

                    .forecasttoday-datail-chart {
                        width: 100%;
                        height: 300px;
                    }

                    .forecasttoday-datail-table {
                        display: flex;
                        justify-content: space-between;
                        padding-left: 5px;

                        .forecasttoday-datail-columns {

                            span {
                                display: block;
                            }

                            img {
                                display: block;
                                margin: 5px 0;
                            }
                        }
                    }
                }
            }
        }
    }



    body {
        font-size: 1em;
        color: #fff;
        background: var(--background-color);
        width: 100vw;
        height: 100vh;
    }

    #root {
        width: 100%;
        height: 100vh;
    }

    .wrapper {
        color: white;
        background-color: #c4d4db;
        width: vw;
        height: hw;

        header {
            padding: 10px;
            display: flex;
            border-bottom: 1px solid #397dbf;
        }

        header,
        footer {
            background-color: #154370;
        }

        footer {
            height: 100%;
            text-align: center;
            padding-top: 10px;
            border-top: 1px solid #397dbf;
        }


        .logo-mobile {
            display: none;
        }

        @media (max-width: 1360px) {
            header {
                position: fixed;
                width: 100%;
                z-index: 10;
            }

            .list-session {
                display: block;
                position: fixed;
                width: 100%;
                top: 55px;
                z-index: 10;
            }

            .list-session .list-group-item {
                padding: 1rem 0.9rem;
                text-align: center;
            }

            .list-session ul li span {
                display: none;
            }

            .list-session ul {
                display: inline;
            }

            .list-session ul li {
                display: inline-block;
                width: 120px;

                &:hover {
                    background-color: #78bad4 !important;
                }
            }
        }

        .sections {
            display: flex;
            flex-wrap: nowrap;

            .title-section {
                margin: 10px 0 10px 0;
                border-bottom: 1px solid #ffffff;
                font-size: 1.5em;
            }

            .partners-section {
                color: #000000;
                padding: 60px 10px 10px;
                height: 100%;

                .card:first-child {
                    margin-top: 0;
                }

                .card {
                    margin-top: 10px;
                }

            }

            .principal-section {

                height: 100%;
                width: calc(100% - 475px);
                overflow: hidden;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover !important;
                height: 100vh;
                display: flex;
                align-items: center;
                justify-content: center;


                img {
                    filter: invert(100%);
                    max-width: 350px;
                }

                a {
                    display: flex;
                    color: inherit;
                }

                .card {
                    color: #154370;

                    .card-info {
                        text-align: right;
                        display: block;

                        span {
                            padding: 5px;

                            a {
                                display: inline;
                            }
                        }
                    }

                    .card-image {

                        img {
                            width: 100%;
                        }
                    }

                    .card-footer {
                        text-align: center;
                    }
                }
            }

            .access_and_news-section {
                background: rgb(0, 0, 0);
                background: var(--background-color);
                color: #FFF;
                padding: 50px 75px;
                height: 100vh;
                text-align: center;
                width: 475px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .card:first-child {
                    margin-top: 0;
                }

                .card {
                    background: rgba(255, 255, 255, 0.247);
                    border-radius: 10px;
                    backdrop-filter: blur(13.5px);
                    -webkit-backdrop-filter: blur(13.5px);
                    border: solid 1px #154370;

                    .card-body {
                        .avatar-login {
                            max-width: 50%;
                        }

                        #form-access {
                            .row {
                                padding: 5px 0;
                            }

                            .btn-primary {
                                width: 100%;
                                font-weight: bold;
                            }
                        }
                    }
                }

                .access-title {
                    margin-top: -10px;
                    margin-bottom: 10px;
                    font-size: 1.2em;
                    font-weight: bold;
                }

                .parceiro {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .parceiro-title {
                        font-weight: bold;
                        font-size: 20px;
                    }

                    span {
                        padding: 3px;
                    }

                    span img {
                        height: 150px;
                        filter: drop-shadow(2px 4px 6px black);
                    }

                    button {
                        background-color: #42b72a;
                        color: #ffffff;
                        border: none;
                        border-radius: 3px;
                        padding: 6px 12px;
                        font-weight: bold;

                        &:hover {
                            background-color: #154370;
                            color: #ffffff;
                        }
                    }
                }
            }

        }

        @media (min-width: 1px) and (max-width: 991.98px) {

            .logo-mobile {
                display: block;
                margin: 0 auto;
            }

            .sections {
                display: block;
                flex-direction: column;
                align-items: center;

                .partners-section {
                    padding-top: 10px;
                }

                .logo {
                    display: none;
                }

                .principal-section {
                    position: relative;
                    width: 100%;
                    background-position: center !important;
                    align-items: flex-start;

                    img {
                        filter: drop-shadow(2px 4px 6px black);
                        max-width: 375px;
                        width: 80%;
                        margin-top: 10px;
                    }
                }

                .access_and_news-section {
                    position: absolute;
                    top: 0;
                    background: transparent;
                    padding: 0;
                    width: 100%;
                    justify-content: space-evenly;
                    align-items: center;

                    .card {
                        max-width: 375px;
                        width: 85%;
                        background: rgba(255, 255, 255, 0.01);
                        backdrop-filter: blur(13.5px);
                    }

                    .avatar-login {
                        display: none;
                    }

                    .access {
                        hr {
                            display: none;
                        }
                    }
                }
            }

            .access {
                .avatar-login {
                    float: left;
                    width: auto;
                    height: 150px !important;
                    margin-right: 10px;
                    background-color: khaki;
                }
            }
        }

    }

    fieldset,
    .form {

        [class*="col-"]:nth-child(n+2) {
            padding-left: 3px;
            padding-right: 3px;
        }

        [class*="col-"]:last-child {
            padding-left: 6px;
            padding-right: 6px;
        }

        [class*="col-"] {
            padding: 0 6px;
        }

    }

    .form-label {
        margin-top: 0.3rem;
        margin-bottom: 0.1rem;
        font-size: 0.80rem;
    }

    .form-control {
        padding: 0.1rem 0.75rem;
    }

    .form-control-color {
        padding: 0.02rem;
        width: 100%;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: #bbc1ca;
    }

    .form-select {
        padding: 0.1rem 0.75rem;
    }

    .input-group-text {
        padding: 0.1rem 0.75rem;
        background-color: #bbc1ca;
    }


    .form-identy-registry {
        font-size: 0.85rem;
        display: flex;
        flex-direction: column;
        text-align: right;

    }

    .form-datasource {
        white-space: nowrap;
        overflow-x: hidden;
    }

    .form-row-divider {
        margin: 5px 0;
    }

    .data-group {
        margin: 1px 4px;

        .data-label {
            font-size: 0.85rem;
            font-weight: bold;
            margin-right: 10px;
        }

        .data-source {
            display: block;
            padding: 2px 6px;
            background-color: #fff;
            border-radius: 0.25rem;
            white-space: nowrap;
        }
    }

    .mail-dispach {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        vertical-align: center;

        h1 {
            text-align: center;
        }

        img {
            margin: 20px 0;
        }
    }

    .modal-title {
        align-items: center;

        svg {
            margin-top: -7px;
            margin-right: 10px;
        }
    }


    .portal {
        align-items: stretch;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: relative;

        .portal:before {
            width: 100%;
            height: 264px;
            position: absolute;
            top: 0;
            left: 0;
            background: #154370;
            content: " ";
        }

        .logo {
            height: 30px;
        }

        .align-baseline {
            vertical-align: baseline !important;
        }

        .align-top {
            vertical-align: top !important;
        }

        .align-middle {
            vertical-align: middle !important;
        }

        .align-bottom {
            vertical-align: bottom !important;
        }

        .align-text-bottom {
            vertical-align: text-bottom !important;
        }

        .align-text-top {
            vertical-align: text-top !important;
        }

        .position-static {
            position: static !important;
        }

        .position-relative {
            position: relative !important;
        }

        .position-absolute {
            position: absolute !important;
        }

        .position-fixed {
            position: fixed !important;
        }

        .position-sticky {
            position: -webkit-sticky !important;
            position: sticky !important;
        }

        .ml-auto,
        .mx-auto {
            margin-left: auto !important;
        }

        .display-none {
            display: none;
        }

        .sidebar {
            min-width: 250px;
            max-width: 250px;
            transition: all ease-in-out .2s;
            background: transparent;
            border-right: 0;
            z-index: 1;

            .sidebar-content {
                transition: all ease-in-out .2s;
                height: 100%;
                min-height: 100%;
                display: flex;
                flex-direction: column;

                .sidebar-brand {
                    padding: 0.85rem 1rem;
                    font-size: 1.175rem;
                    font-weight: 500;
                    color: #ffffff;
                    width: 100%;
                    display: block;
                    text-decoration: none;
                    background: rgba(255, 255, 255, 0.09);

                    img {
                        height: 28px;
                        margin-right: .25rem;
                        fill: #154370;
                    }
                }

                .sidebar-user {
                    padding: 1.5rem 1rem 1rem;
                    color: white;
                    text-align: center;
                    background: rgba(255, 255, 255, 0.09);

                    border-bottom: 1px dashed #154370;

                    small {
                        display: block;
                        font-size: 0.75em;
                    }

                    img {
                        width: 64px;
                        height: 64px;
                    }
                }

                .sidebar-user-profile {
                    padding: 10px;
                    font-size: 0.85rem;
                    color: #154370;
                    text-align: center;
                    background: #eef3f8;
                    border-bottom: 1px dashed #154370;

                    label {
                        margin-bottom: 10px;
                    }

                    select {
                        padding: 3px 0;
                        color: #154370;
                        border: 1px solid #154370;
                        background-color: #d9e8f8;
                        border-radius: 0.25rem;
                    }
                }

                .sidebar-nav {
                    margin-bottom: 0;
                    padding-left: 0;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex: 1;
                    background: rgba(255, 255, 255, 0.09);

                    overflow-x: hidden;
                    overflow-y: auto;

                    .sidebar-header {
                        background: transparent;
                        color: #adb5bd;
                        padding: 0.375rem 1.1rem;
                        font-size: 0.75rem;
                        font-weight: 500;
                        text-transform: none;
                        text-align: center;
                    }

                    button.sidebar-link {
                        border: none !important;

                        &:hover {
                            border: none !important;
                        }
                    }

                    .sidebar-divider {
                        border: 1px inset currentColor;

                        display: block;
                        unicode-bidi: isolate;
                        padding: 0;
                        opacity: 0.5;
                    }

                    .sidebar-link,
                    a.sidebar-link,
                    button.sidebar-link {
                        display: block;
                        padding: 0.65rem 0.85rem;
                        color: white;
                        font-weight: 400;
                        background: transparent;
                        transition: background .1s ease-in-out;
                        position: relative;
                        text-decoration: none;
                        cursor: pointer;
                    }

                    .sidebar-link svg,
                    .sidebar-link .fas,
                    .sidebar-link .far,
                    .sidebar-link .fal,
                    .sidebar-link .fab,
                    a.sidebar-link svg,
                    a.sidebar-link .fas,
                    a.sidebar-link .far,
                    a.sidebar-link .fal,
                    a.sidebar-link .fab,
                    button.sidebar-link svg,
                    button.sidebar-link .fas,
                    button.sidebar-link .far,
                    button.sidebar-link .fal,
                    button.sidebar-link .fab {
                        margin-right: .75rem;
                        color: white;
                    }

                    .sidebar-link:focus {
                        outline: 0;
                    }

                    .sidebar-link:hover {
                        background: #ffffff5d;
                    }

                    .sidebar-link:hover svg,
                    .sidebar-link:hover .fas,
                    .sidebar-link:hover .far,
                    .sidebar-link:hover .fal,
                    .sidebar-link:hover .fab {
                        color: white;
                    }

                    .sidebar-item.active,
                    .sidebar-item.active .sidebar-link:hover {
                        color: rgba(255, 255, 255, 0.692);
                        background: rgba(255, 255, 255, 0.1);
                    }

                    .sidebar-item.active>.sidebar-link svg,
                    .sidebar-item.active>.sidebar-link .fas,
                    .sidebar-item.active>.sidebar-link .far,
                    .sidebar-item.active>.sidebar-link .fal,
                    .sidebar-item.active>.sidebar-link .fab,
                    .sidebar-item.active .sidebar-link:hover svg,
                    .sidebar-item.active .sidebar-link:hover .fas,
                    .sidebar-item.active .sidebar-link:hover .far,
                    .sidebar-item.active .sidebar-link:hover .fal,
                    .sidebar-item.active .sidebar-link:hover .fab {
                        color: rgba(255, 255, 255, 0.692);
                        color: white;
                    }

                    .sidebar-dropdown .sidebar-link {
                        padding: 0.35rem 1.5rem 0.35rem 2.75rem;
                        color: white;
                        background: rgba(255, 255, 255, 0.1);
                        font-weight: 400;
                        font-size: 90%;
                    }

                    .sidebar-dropdown .sidebar-item .sidebar-link:hover {
                        color: rgba(255, 255, 255, 0.692);
                        background: rgba(255, 255, 255, 0.1);
                        font-weight: 400;
                    }

                    .sidebar-dropdown .sidebar-item.active .sidebar-link {
                        color: rgba(255, 255, 255, 0.692);
                        background: rgba(255, 255, 255, 0.1);
                        font-weight: 400;
                    }

                }

            }

            .sidebar-badge {
                position: absolute;
                right: 15px;
                top: 13px;
            }

            .sidebar-dropdown .sidebar-badge {
                top: 7px;
            }

            .sidebar-right.toggled {
                margin-left: auto;
                margin-right: -250px;
            }

            .sidebar-right.toggled .sidebar-content {
                left: auto;
                right: -250px;
            }

            @media (min-width: 1px) and (max-width: 991.98px) {
                .sidebar-right {
                    margin-left: auto;
                    margin-right: -250px;
                }

                .sidebar-right .sidebar-content {
                    left: auto;
                    right: -250px;
                }

                .sidebar-right.toggled {
                    margin-left: auto;
                    margin-right: 0;
                }

                .sidebar-right.toggled .sidebar-content {
                    left: auto;
                    right: 0;
                }

                .sidebar-right-collapsed {
                    margin-left: auto;
                    margin-right: 0;
                }

                .sidebar-right-collapsed .sidebar-content {
                    left: auto;
                    right: 0;
                }

                .sidebar-right-collapsed.toggled {
                    margin-left: auto;
                    margin-right: -250px;
                }

                .sidebar-right-collapsed.toggled .sidebar-content {
                    left: auto;
                    right: -250px;
                }
            }

            .sidebar-logout {
                cursor: pointer;
                background: rgba(255, 255, 255, 0.09);

                display: block;
                padding: 0.35rem 1.5rem;
                clear: both;
                font-weight: 400;
                text-align: center;
                white-space: nowrap;
                border-top: 1px inset currentColor;

                svg,
                img {
                    height: 28px;
                    margin-right: .4rem;
                }
            }

        }

        .sidebar [data-toggle="collapse"] {
            position: relative;

            &:before {
                content: " ";
                border: solid;
                border-width: 0 .1rem .1rem 0;
                display: inline-block;
                padding: 2.5px;
                transform: rotate(-45deg);
                position: absolute;
                top: 1.2rem;
                right: 1.25rem;
                transition: all .2s ease-out;
            }
        }

        .sidebar [aria-expanded="true"]:before,
        .sidebar [data-toggle="collapse"]:not(.collapsed):before {
            transform: rotate(45deg);
        }

        .sidebar-toggle {
            cursor: pointer;
            width: 26px;
            height: 26px;
            margin-left: 0.5rem;
        }

        .sidebar.toggled {
            margin-left: -250px;
        }

        .sidebar.toggled .sidebar-content {
            left: -250px;
        }

        @media (min-width: 1px) and (max-width: 991.98px) {
            .sidebar {
                margin-left: -250px;
            }

            .sidebar .sidebar-content {
                left: -250px;
            }

            .sidebar.toggled {
                margin-left: 0;
            }

            .sidebar.toggled .sidebar-content {
                left: 0;
            }

            .sidebar-collapsed {
                margin-left: 0;
            }

            .sidebar-collapsed .sidebar-content {
                left: 0;
            }

            .sidebar-collapsed.toggled {
                margin-left: -250px;
            }

            .sidebar-collapsed.toggled .sidebar-content {
                left: -250px;
            }
        }

        .main {
            width: 100%;
            min-height: 100vh;
            transition: all ease-in-out .3s;

            @media (max-width: 1360px) {
                .main {
                    width: 100vw;
                    min-width: 100vw;
                }
            }

        }

    }

    .margin-top-10 {
        margin-top: 10px;
    }

    .dashboard {
        margin: 0 10px;
        color: #ffffff;
        overflow: auto;
        height: -webkit-fill-available;
        margin-bottom: 100px;

        .dashboard-header {
            display: flex;
            justify-content: space-between;
            color: #ffffff;
            border-bottom: 1px solid #ffffff;
            margin-bottom: 10px;
            white-space: nowrap;
            margin-right: 11px;
            margin-left: 11px;

            .dashboard-reserved {
                width: 100%;
            }

            .dashboard-corporation {
                text-align: right;
                font-size: 0.9rem;
                display: flex;
                align-items: flex-end;
            }

        }



        .page-body {
            overflow: auto;
            max-height: calc(100vh - 120px);
        }

        .dashboard-sweet {
            color: #ffffff;

            .container {
                margin: 0;
                min-width: 100%;
            }

            .page-sweet {
                .picker-button {
                    background-color: transparent;
                    border: none;
                }
            }
        }

        .climate-partials {

            .climate-current {
                display: flex;
                justify-content: space-between;
                padding: 5px;
                border-radius: 5px;

                .climate-current-main {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    .climate-current-icon {
                        max-width: 65px;
                        max-height: 65px;
                    }

                    .current-main {
                        display: flex;

                        .climate-current-temperature {
                            font-size: 2rem;

                            span {
                                font-size: .8rem;
                            }
                        }

                        .climate-current-label {
                            line-height: .8rem;
                            font-size: 1rem;
                        }
                    }
                }

                .climate-current-information {
                    display: flex;
                    justify-content: space-between;
                    color: #ababab;
                    text-align: center;
                    font-size: 0.8rem;

                    .climate-current-temperature {
                        padding: 0.2rem;
                    }
                }
            }

            .climate-hour {
                display: flex;
                justify-content: space-between;

                .card-hour {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background: rgba(255, 255, 255, 0.09);
                    border-radius: 5px;
                    color: #ababab;
                    font-size: 0.8rem;
                    margin: 0 2px;
                    justify-content: center;

                    .climate-current-icon {
                        max-width: 55px;
                        max-height: 55px;
                    }
                }
            }
        }

        .card-timer {
            display: flex;
            justify-content: space-around;
            padding: 1rem 0;

            .row {
                justify-content: center;
                font-weight: bold;

                input {
                    background-color: transparent;
                    border: none;
                    text-align: center;
                    color: white;
                }
            }
        }

        .grafics {
            padding: 5px;
            color: white;
            border: 1px solid #6c757d;
            border-radius: 0.25rem;

            height: 250px;
        }

        .card {
            background: rgba(255, 255, 255, 0.055);
            backdrop-filter: blur(13.5px);
            color: white;

            .card-img,
            .card-img-top,
            .card-img-bottom {
                max-width: 100%;
                height: auto;
            }

            @media all and (-ms-high-contrast: none) {

                .card-img,
                .card-img-top,
                .card-img-bottom {
                    height: 100%;
                }
            }

            .card-actions {
                display: flex;

                #form-date-start {
                    background-color: transparent;
                    border: none;
                    color: white;
                }
            }

            .card-actions .input-group {
                margin: 0 5px;
            }

            .card-actions input[type='date'] {
                max-width: 140px;
            }

            .card-actions input[type='text'] {
                max-width: 90px;
            }

            .card-actions a {
                text-decoration: none;
            }

            .card-actions svg {
                width: 16px;
                height: 16px;
            }

            .card-actions .dropdown {
                line-height: 1.4;
            }

            .card-img-hover {
                transition: all .1s ease-in-out;
            }

            .card-img-hover:hover {
                transform: scale(1.035);
            }

        }

        .movement-resume {
            display: flex;
            height: 100%;

            .movement-resume-item {
                width: 100%;
                padding: 5px;
                margin: 0 5px;
                border-radius: 0.25rem;
                display: flex;
                align-items: center;
                justify-content: center;

                // svg {
                //     width: 2rem;
                //     height: 2rem;
                // }
                img {
                    width: 3rem;
                    height: 3.5rem;
                }

                .movement-resume-img {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 3rem;


                    // svg {
                    //     width: 2rem;
                    //     height: 2rem;
                    // }
                }

                .movement-resume-label {
                    text-align: center;
                    font-weight: bold;
                    font-size: 1.3rem;
                }

                .movement-resume-data {
                    font-size: 3.5rem;
                    color: #ffffff;
                    font-weight: bold;
                    text-align: center;
                    border-radius: 0.25rem;
                }
            }
        }

        @media (max-width: 1360px) {
            .dashboard-header {
                flex-direction: column;
            }

            .movement-resume-data {
                margin: 0.2rem 0;
            }

            // .climate-current {
            //     flex-direction: column;
            //     margin-top: 0.5rem;

            //     .climate-current-main {
            //         flex-direction: row !important;
            //         padding-bottom: 0.2rem;

            //         .climate-current-information {
            //             flex-direction: column;
            //             text-align: flex-end;
            //         }
            //     }
            // }

            .card-timer {
                display: initial;
                padding: 0.2rem 0;
            }

            .card-header {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
            }
        }

    }

    .rule-invalid {
        color: lightcoral;
    }

    input[type='file'] {
        display: none;
    }

    input[type='number'] {
        padding-right: 3px;
    }

    input[type=number]::-webkit-inner-spin-button {
        margin-left: 5px;
    }

    .input-wrapper label {
        background-color: #2980b9;
        border-radius: 5px;
        color: #fff;
        margin: 10px;
        padding: 6px 12px;

        svg {
            margin-right: 10px;
            margin-bottom: 3px;
        }

        &:hover {
            background-color: #2980b9;
        }
    }

    .white-space {
        white-space: nowrap;
    }

    .table> :not(caption)>*>* {
        padding: 5px 5px;
        line-height: 25px;
    }

    .table {
        color: white;
    }

    .table tbody tr {
        &:hover {
            background-color: #b2cee0;
            color: #000000;
        }
    }

    .table-actions span {
        display: inline-flex;
        padding: 5px 6px;
        border-radius: 0.25rem;

        &:last-child {
            margin: 0;
        }

        &:hover {
            background-color: #b2cee0;
        }
    }


    .h4 {
        font-size: 1.2rem;
    }

    .btn {
        padding: 5px 10px;
    }

    .modal {
        color: white;
        font-size: 0.85rem;

        .modal-content {
            background: var(--background-color);
            border: none;
        }

        .modal-header {
            padding: 10px;

            .modal-title {
                margin: 0;
                font-weight: bold;
            }
        }

        .modal-body {
            padding: 10px;
        }

        .modal-footer {
            padding: 10px;
            display: flex;
            justify-content: space-between;
        }
    }

    .message-context {
        .modal-content {
            box-shadow: 0 0 20px #011822;
            border-radius: 0.25rem;
        }
    }

    .cardboard {
        width: 100%;
        height: 100vh;
        overflow: auto;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        text-align: center;

        .card {
            background: rgba(255, 255, 255, 0.187);
            backdrop-filter: blur(3px);
            text-align: initial;
            color: white;

            .header {
                flex-wrap: wrap;

                a {
                    text-align: center;
                }
            }
        }

        .cardboard-image {
            width: 100%;
            max-width: 350px;
            padding: 10px 15px;
        }

        .box-image {
            display: flex;
            width: 35px;
            height: 35px;
            padding: 0;

            img {
                width: 100%;
                height: auto;
                border-radius: 50%;
            }
        }

        .cardboard-title {
            font-weight: bold;
        }

        .cardboard-label {
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);

            span {
                color: white;
                font-weight: bold;
            }
        }

        .card-info {
            text-align: center;

            div {
                margin: 0.2rem 0;
            }

            .cardboard-status.Regular {
                color: #34d84f;
            }

            .cardboard-status.Bloquado {
                color: #d83434;
                font-weight: bold;
            }


            @keyframes glowing {
                0% {
                    background-color: #bc0501;
                    box-shadow: 0 0 3px #bc0501;
                }

                50% {
                    background-color: #f70100;
                    box-shadow: 0 0 3px #f70100;
                }

                100% {
                    background-color: #bc0501;
                    box-shadow: 0 0 3px #bc0501;
                }
            }

            .cardboard-alert {
                animation: glowing 1500ms infinite;
                max-width: 120px;
                padding: 0.2rem 1rem;
                border-radius: 0.2rem;
                margin: 0.3rem;
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        .localization {
            text-align: center;
        }
    }

    .container {
        padding: 0;
    }

    .page {
        color: #ffffff;

        .page-header {
            display: flex;
            justify-content: space-between;
            color: #ffffff;
            border-bottom: 1px solid #ffffff;
            margin-bottom: 10px;
            white-space: nowrap;
            margin-left: 11px;
            margin-right: 11px;


            .page-reserved {
                width: 100%;
            }

            .page-corporation {
                margin-right: 10px;
                text-align: right;
                font-size: 0.9rem;
                display: flex;
                align-items: flex-end;
            }

        }

        .page-body {
            overflow: auto;
            max-height: calc(100vh - 120px);
        }

        .container {
            align-content: center;
        }

        .container-min {
            padding: 0 6px;
        }

        .page-session {
            color: #153d77;
            padding: 0 10px;
        }

        @media (max-width: 576px) {
            .page-session .card .list-group-item {
                padding: 1rem 0.9rem;
            }

            .page-session .card ul li span {
                display: none;
            }

            .page-session .card ul {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
            }

            .page-session .card ul li {
                display: inline-block;
                width: auto;

                &:hover {
                    background-color: #78bad4 !important;
                }
            }
        }

        .page-sweet {
            color: #dadada;
            padding: 0 10px;
        }

        .table-row-divider td {
            margin: 0;
            padding: 0;
            background-color: #154370;
            height: 2px;
        }

        .box-avatar {
            margin: auto;
            padding: 0 6px 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }
        }

        .box-code {
            margin: auto;
            display: flex;
            flex-direction: row;

            align-items: center;
            justify-content: center;

            // width: 100px;
            // height: 100px;
            //background-color: transparent;
            //color: #ebebeb;
            //border-radius: 0.25rem;
            //border: 1px solid #154370;

            svg {
                background-color: transparent;
                width: 100px;
                height: 100px;
            }
        }

        .card {
            backdrop-filter: blur(13.5px);
            margin-bottom: 1.5rem;
            font-size: 0.9rem;
        }

        a.card {
            cursor: pointer;
            text-decoration: none;
        }

        .card-header {
            background: transparent;
        }

        .card-filter {
            padding: 5px;
            height: 180px;
            border: 1px solid white;
            border-radius: 0.25rem;
            background: #153D77;

            display: none;

            .card-filter-header {
                border-bottom: 1px solid #677385;
                font-weight: bold;
            }

            .card-filter-body {
                grid-row: 2;
            }

            .card-filter-footer {
                border-top: 1px solid #677385;
                padding-top: 5px;
                text-align: right;
            }
        }

        .card-filter.active {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr auto;
        }

        .card-body {
            padding-top: 0.75rem;
        }

        .card-title {
            color: white;
            font-size: 1.1rem;
            line-height: 1;
            margin: 6px 0;
        }

        .card-subtitle {
            font-weight: 400;
        }

        .card-table {
            margin-bottom: 0;
        }

        .card-table tr td:first-child,
        .card-table tr th:first-child {
            padding-left: 1.25rem;
        }

        .card-table tr td:last-child,
        .card-table tr th:last-child {
            padding-right: 1.25rem;
        }

        .card-img,
        .card-img-top,
        .card-img-bottom {
            max-width: 100%;
            height: auto;
        }

        @media all and (-ms-high-contrast: none) {

            .card-img,
            .card-img-top,
            .card-img-bottom {
                height: 100%;
            }
        }

        .card-body .card-actions {
            display: flex;
        }

        .card-actions .input-group {
            margin: 0 5px;
        }

        .card-actions input[type='date'] {
            max-width: 140px;
        }

        .card-actions input[type='text'] {
            max-width: 90px;
        }

        .card-actions a {
            text-decoration: none;
        }

        .card-actions svg {
            width: 16px;
            height: 16px;
        }

        .card-actions .dropdown {
            line-height: 1.4;
        }

        .card-img-hover {
            transition: all .1s ease-in-out;
        }

        .card-img-hover:hover {
            transform: scale(1.035);
        }

        .card-order {
            margin: 0;
            padding: 0;
            font-size: 4rem;
            font-weight: bold;
        }

        .card-body {

            [class*="col-"]:nth-child(n+2) {
                padding-left: 3px;
                padding-right: 3px;
            }

            [class*="col-"]:last-child {
                padding-left: 6px;
                padding-right: 6px;
            }

            [class*="col-"] {
                padding: 0 6px;
            }

        }

    }

    .text-muted-info {
        margin: 0;
        font-size: 0.85em;
        line-height: -5;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    .text-left {
        text-align: left;
    }

    .postits {
        display: flex;

        .postit {
            padding: 5px;
            border-radius: 0.4rem;
            width: 100%;
            border: none;

            .postit-header {
                font-size: 1rem;
                text-align: center;
                font-weight: bold;
            }

            .postit-body {
                font-size: 2.5rem;
                text-align: center;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .postit.red {
            .postit-body {
                svg {
                    color: #ff0000;
                    margin-right: 0.5rem;
                }
            }
        }

        .postit.orange {
            .postit-body {
                svg {
                    color: #ffff00;
                    margin-right: 0.5rem;
                }
            }
        }

        .postit.green {
            .postit-body {
                svg {
                    color: #00FF00;
                    margin-right: 0.5rem;
                }
            }
        }

        .postit:first-child {
            margin-left: 0;
        }

    }

    @media (max-width: 991.98px) {
        .postits {
            flex-direction: column-reverse;

            .postit {
                margin: 5px 0;
            }
        }
    }

    .post-red {
        background-color: #d83434;
        color: #b6b6b6;
    }

    .post-orange {
        background-color: #f7f32e;
        color: #7a7a7a;
    }

    .post-green {
        background-color: #34d84f;
        color: #7a7a7a;
    }

    .form-steps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        gap: 10px;

        li {
            padding: 8px 6px;

            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                width: 40px;
                height: 40px;
            }
        }

        li.active {
            color: #427cd4;
            font-weight: bold;
        }
    }

    .box-monthly {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 70px;

        .box-monthly-data {
            flex-grow: 1;
        }
        .box-monthly-link {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            gap: 5px;
        }
    }

    .monthly-difference-red {
        color: #d83434;
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 20px;
    }

    .monthly-difference-green {
        color: #357e41;
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 20px;
    }

    .monthly-pcls {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        gap: 10px;
        margin: 0;
        padding: 0;

        .payment-made {
            padding-top: 5px;
            text-align: center;
        }
    }

    .monthly-pcl-waiting,
    .monthly-pcl-late,
    .monthly-pcl-pay,
    .monthly-pcl,
    .monthly-pcl-undefined {
        width: 357px;
        background: #acb6c2;
        border-radius: 0.50rem;
        padding-bottom: 10px;
        padding-right: 15px;
    }

    .monthly-pcl-waiting {
        background: #caad6d;
    }

    .monthly-pcl-late {
        background: #ca6d6d;
    }

    .monthly-pcl-pay {
        background: #6dca81;
    }

    .monthly-pcl-number {
        display: block;
        padding-top: 10px;
        height: 100%;
        font-size: 2rem;
        text-align: center;
    }

    .monthly-gc-waiting,
    .monthly-gc-late,
    .monthly-gc-pay,
    .monthly-gc-none,
    .monthly-gc {
        width: 100%;
        height: 90%;
        border-radius: 0.25rem;
        text-align: center;
        padding: 5px 2px;
    }

    .monthly-gc-value {
        font-size: 0.7rem;
    }
    .monthly-gc-none {
        background: 'none';
    }
    .monthly-gc {
        background: #acb6c2;
    }
    .monthly-gc-waiting {
        background: #caad6d;
    }

    .monthly-gc-late {
        background: #ca6d6d;
    }

    .monthly-gc-pay {
        background: #6dca81;
    }

    .monthly-gc-number {
        display: block;
        padding-top: 10px;
        height: 100%;
        font-size: 2rem;
        text-align: center;
    }

    .w-box {
        display: flex;
    }

    .w-10 {
        width: 10%;
    }

    .w-20 {
        width: 20%;
    }

    .w-30 {
        width: 30%;
    }

    .w-40 {
        width: 40%;
    }

    .w-60 {
        width: 60%;
    }

    .w-70 {
        width: 70%;
    }

    .w-80 {
        width: 80%;
    }

    .w-90 {
        width: 90%;
    }

    .w-100 {
        width: 100%;
    }

    .h-90 {
        height: 90%;
    }

    .h-95 {
        height: 95%;
    }

    .h-100 {
        height: 100%;
    }

    .card {
        background: #464E5C;
        border-radius: 4px;
    }


    .movements,
    .budgets,
    .schedules {
        .card-actions-time {
            display: flex;
            flex-direction: row;
        }

        @media (max-width: 991.98px) {
            .card-header {
                display: flex;
                flex-direction: column-reverse;

                .card-title {
                    text-align: center;
                }
            }

            .card-actions-time {
                display: initial;
            }

            .input-group {
                margin: 0.1rem;
            }

            .btn-group {
                button {
                    margin: 0 0.3rem;
                }
            }
        }
    }

    .nav-tabs {
        button {
            color: white;
        }

        button:hover {
            color: white;
        }

        .nav-link.active {
            color: #000000;
        }
    }

    @media (max-width: 1360px) {
        .lineSelector {
            display: none;
            width: 0;
        }
    }

    .nav-translate {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }

    .nav-translate-item {
        opacity: 0.2;
    }

    .nav-translate-item.active {
        opacity: 1;
    }

    .input-file-text {
        margin: 4px 0;
    }

    .input-file-button {
        line-height: 20px;
        margin-left: 10px;
        padding: 8px 12px;
        background-color: #0d6efd;
        border-radius: 0.25rem;
    }

    .input-file-button:hover {
        background-color: #0e58c7;
    }

    .input-file-button {
        border-left: 1px solid white;
    }

    .picker-input-enabled,
    .picker-input-enabled:hover {
        background-color: #2bc217 !important;
        border-color: #25a514;
    }

    .picker-input {
        padding: 0.1rem 0.75rem;

        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        color: #fff;
        background-color: #0d6efd !important;
        border-color: #0d6efd;
    }

    .picker-input:hover {
        color: #fff;
        background-color: #0b5ed7 !important;
        border-color: #0a58ca;
    }

    .filter-selectred {
        padding: 0.5rem 0.75rem;

        color: #ffffff;
        border-radius: 0.25rem;
        width: 95%;
    }

    .tab-contract {
        min-height: 260px;
        padding-bottom: 10px;
    }

    .laucher {
        .group-input {
            display: flex;
        }

        @media (max-width: 991.98px) {
            .card-header {
                display: flex;
                flex-direction: column-reverse;

                .card-title {
                    text-align: center;
                }
            }

            .card-actions {
                display: initial;
            }

            .input-group {
                margin: 0.1rem;
            }

            .btn-group {
                button {
                    margin: 0 0.3rem;
                }
            }

            .group-input {
                flex-direction: column;
            }
        }

    }

    .billet-payment {
        border-radius: 0.25rem;
        border-color: #f1e5bc;
        background-color: #9b9a9b;
        color: #000000;
        padding: 10px;

        .whatsapp {
            margin-top: 0.01rem;
            cursor: pointer;

            svg {
                margin-right: 0.1rem;
            }
        }
    }

    .toast-container {
        position: absolute;
        right: 5%;
        z-index: 99999;
    }

    .contract-vessel {
        padding: 0;

        div {
            padding: 0;
        }
    }

    @media (max-width: 991.98px) {
        .financial {
            .card-actions-contract-launchers {
                width: 100%;
                justify-content: space-between !important;

                .input-data {
                    flex-direction: column !important;
                }

                .card-actions-item {
                    .btn-group {
                        flex-direction: column;
                    }
                }
            }
        }

        #modal-cadastre-launcher-form {
            .modal-header {
                flex-direction: column;
            }
        }
    }

    .align-right {
        text-align: right;
    }

    .vertical-align-middle {
        vertical-align: middle;
    }

    .react-switch {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .react-switch-description {
        width: 100%;
    }

    .react-switch-checkbox {
        height: 0;
        width: 0;
        display: none;
    }

    .react-switch-label {
        position: relative;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        min-width: 35px;
        height: 20px;
        background: grey;
        border-radius: 50px;
        transition: background-color .2s;
    }

    .react-switch-label .react-switch-button {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        transition: 0.2s;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
        background-color: #696a6b;
    }

    .react-switch-checkbox:checked+.react-switch-label .react-switch-button {
        left: calc(100% - 2px);
        transform: translateX(-100%);
        background-color: white;
    }

    .react-switch-label:active .react-switch-button {
        width: 16px;
    }

    .gallery-photos {
        display: flex;
        flex-direction: row;

        justify-content: space-around;

        background-color: #ffffff;
        border-radius: 0.5rem;


    }



    .margin-auto {
        margin: auto;
    }

    .table-label-vertical {
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-lr;
        transform: rotate(-180deg);
        font-size: 0.8em;
    }

    .table-label {
        font-size: 0.8em;
    }

    .table-header,
    .table-content {
        margin: 0;
        padding: 0;

    }

    .table-header {
        table {
            margin-bottom: 0;
        }
    }

    .table-content {
        max-height: 500px;
        overflow-x: auto;

        table {
            margin-bottom: 0;
            border-bottom: 1px solid #fff;
        }
    }

    .dropdown-select {
        padding: 0 12px;

        .dropdown-item {
            padding: 0 12px;
        }
    }

    .btn-icon {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: flex-end;
    }

    .fieldset-buttons {
        padding: 24px 0 10px;
    }

    .table-column-color {
        border-radius: 0.25rem;
        text-align: center;
    }

    .table-column-block30x30 {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 0.25rem;
    }

    .table-column-blockAutox30 {
        display: block;
        width: auto;
        height: 30px;
        border-radius: 0.25rem;
    }

    .schedule-calendar {
        padding: 5px;
        background-color: #ffffff;
        color: #000000;
        border-radius: 0.25rem;

        .rbc-calendar {
            height: 700px;
        }
    }

    .table-list-record tbody td:last-child {
        width: 30px;
    }

    .other-footer {
        border-top: 1px solid #677385;
        margin-top: 10px;
        padding-top: 10px !important;
        text-align: right;
    }


    .card-drawer,
    .card-drawer-2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        padding: 3px;

        background-color: #e1ebf3;
        color: #000000;
        border-radius: 0.25rem;
        border: 2px solid #ffffff;

        height: 100px;
        width: 100px;

        .card-drawer-title {
            padding: 2px 4px;
            background-color: #ffffff;
            text-align: center;
            font-weight: 600;
            border-radius: 0.25rem;
            white-space: nowrap;

            overflow: hidden;
            height: 30px;
        }

        .card-drawer-group {
            flex-grow: 1;

            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            white-space: nowrap;
        }

        .card-drawer-number {
            flex-grow: 1;

            text-align: center;
            font-size: 1rem;
            font-weight: bold;
        }

        .card-drawer-fields {
            flex-grow: 1;

            display: flex;
            flex-direction: row;

        }

        .card-drawer-object {
            flex-grow: 1;

            padding: 6px;
            border-radius: 0.25rem;
            display: flex;
            flex-direction: column;

            .card-drawer-text {
                text-align: center;
                font-size: 0.8rem;
                white-space: nowrap;
            }
        }

        .card-drawer-link {
            padding: 0;
            margin: 0;
            font-size: 0.7rem;
            text-align: right;

        }

        .card-drawer-link-master {
            padding: 0;
            margin: 0;
            font-size: 2rem;
            text-align: center;
            color: #cbdceb;
        }

        .card-drawer-reserver {
            position: relative;
            text-align: center;
            font-size: 0.6rem;
            color: #c92f2f;
        }

    }

    .card-drawer-3 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        padding: 3px;

        background-color: #e1ebf3;
        color: #000000;
        border-radius: 0.25rem;
        border: 2px solid #ffffff;

        height: 100%;

        .card-drawer-title {
            padding: 2px 4px;
            background-color: #ffffff;
            text-align: center;
            font-weight: 600;
            border-radius: 0.25rem;
            white-space: nowrap;

            overflow: hidden;
            height: 30px !important;
        }

        .card-drawer-group {
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            white-space: nowrap;
        }

        .card-drawer-number {
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
        }

        .card-drawer-fields {
            background-color: sandybrown;
        }

        .card-drawer-object {
            padding: 5px;
            border-radius: 0.25rem;

            height: 60px;
        }

        .card-drawer-source {
            padding: 0;
            margin: 0;
            text-align: center;
        }

    }

    .card-bookcase {
        display: flex;
        flex-direction: column;

        .card-shelf {
            padding: 3px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-radius: 0.25rem;

            border-top: 1px solid #bce6f1;
            border-bottom: 1px solid #7ca4ad;
            margin: 2px 0;

            .card-shelf-item {
                display: flex;
                flex-direction: column;

                width: 100%;

                .card-shelf-name {
                    font-size: 1rem;
                    font-weight: 600;
                }

                .card-shelf-fields {
                    margin: 3px;
                    padding: 3px 5px;
                }

                .card-shelf-items {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .card-drawer {
                        margin-right: 5px;
                        width: 100%;
                    }
                }

                .card-shelf-items-2 {
                    display: flex;
                    flex-wrap: wrap;

                    .card-drawer-2 {
                        margin-right: 5px;
                        max-width: 135px;
                        max-height: 65px;
                    }

                    .card-drawer-3 {
                        margin-right: 5px;
                        width: 150px;
                        height: 150px;
                    }
                }
            }

            .card-shelf-command {
                margin: 27px 3px;
                display: flex;
                flex-direction: column;
            }
        }
    }


    .financial {
        .financial-card {
            display: flex;
            justify-content: space-between;
        }

        .card-item {
            background-color: #757575;
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0 15px;
            border-radius: 0.25rem;
            padding: 10px 15px 0 15px;
            justify-content: space-between;
            background: rgba(255, 255, 255, 0.01);
            backdrop-filter: blur(13.5px);
            border: solid 1px var(--secondary);

        }

        .ship {
            border-left: 5px solid #6d8de0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .data {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .card-data-group {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .card-data {
                        align-items: center;
                    }
                }

                .img {
                    margin-right: 9px;
                    color: #6d8de0
                }

            }

            .card-data-count {
                font-size: 30px;
                font-weight: 700;
            }
        }

        .monthly-payment {
            border-left: 5px solid #8dc05f;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .data {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                div {
                    display: flex;
                    align-items: center;
                    margin-top: 5px;
                }

            }

            .img {
                margin-right: 10px;
                color: #8dc05f
            }

            .card-data-count {
                font-size: 30px;
                font-weight: 700;
            }
        }

        .delay {
            border-left: 5px solid #e27781;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .data {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                div {
                    margin-top: 5px;
                    display: flex;
                    align-items: center;
                }

            }

            .img {
                margin-right: 9px;
                color: #e27781
            }

            .card-data-count {
                font-size: 30px;
                font-weight: 700;
            }
        }

        .opened {
            border-left: 5px solid #e79761;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .data {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                div {
                    margin-top: 5px;
                    display: flex;
                    align-items: center;
                }

            }

            .img {
                margin-right: 9px;
                color: #e79761
            }

            .card-data-count {
                font-size: 30px;
                font-weight: 700;
            }
        }

        .total {
            border-left: 5px solid #78ccda;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .data {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                div {
                    margin-top: 5px;
                    display: flex;
                    align-items: center;
                }

            }

            .img {
                margin-right: 9px;
                color: #78ccda
            }

            .card-data-count {
                font-size: 30px;
                font-weight: 700;
            }
        }

        .part {
            border-left: 5px solid #63b18b;
            flex-direction: column;
            align-items: initial;
            justify-content: space-between;

            div {
                display: flex;
                margin-top: 5px;
            }

            .img {
                margin-right: 9px;
                color: #63b18b
            }
        }

        .service {
            border-left: 5px solid #db6097;
            flex-direction: column;
            align-items: initial;
            justify-content: space-between;

            div {
                display: flex;
                margin-top: 5px;
            }

            .img {
                margin-right: 9px;
                color: #db6097
            }
        }

        .average-margin {
            border-left: 5px solid #f1ce60;
            flex-direction: column;
            align-items: initial;
            justify-content: space-between;

            div {
                display: flex;
                margin-top: 5px;
            }

            .img {
                margin-right: 9px;
                color: #f1ce60
            }
        }

        .card-data {
            font-size: 20px;
            font-weight: 700;
        }

        .title {
            font-size: 20px;
            font-weight: 500;
        }

        .financial-accompany-tooltip {
            padding: 10px;

            color: #000000;
            background-color: #d8e3e7;
            border: 1px solid #ffffff;
            border-radius: 0.25rem;
            box-shadow: 0 0 10px #1d2a3d;

            span {
                display: block;
            }
        }
    }


    @media (max-width: 991.98px) {
        .financial {
            .financial-card {
                flex-direction: column;

                .card-item {
                    margin: 10px 0;
                }
            }
        }
    }

    .dropdown-color {

        .dropdown-color-toggle {
            margin: 0;
            padding: 2px 5px;

            display: flex;
            flex-direction: row;

            width: 100%;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            color: #212529;
            border-radius: 0.25rem;

            .dropdown-color-selected {
                width: 100%;
                min-width: 30px;
                height: 24px;
                border-radius: 0.25rem;
            }
        }

        .dropdown-color-menu,
        .dropdown-menu {
            position: absolute;
            left: 0;
            top: 0;
            background-color: #e1ebf3 !important;
            z-index: 99999999;
        }

        .dropdown-color-menu {
            background-color: #e1ebf3;
            border-radius: 0.25rem;

            .dropdown-color-item {
                margin: 0;
                padding: 1px 3px;

                display: flex;
                flex-direction: row;

                .dropdown-color-item-block {
                    display: block;
                    margin-right: 5px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .navbar {

        .avatar {
            vertical-align: middle;
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }

        .rotating {
            svg {
                animation: loading 2s linear infinite;

                @keyframes loading {
                    0% {
                        transform: rotate(0);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }

            }
        }
    }

    .isClickable {
        cursor: pointer;
    }

    .badge-principal {
        position: absolute;

        padding: 6px 8px;
        top: 25px;
        right: 50px;
    }

    .roles-check {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        div {
            padding: 2px 10px;
            width: 50%;
        }
    }

    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }



    .quill {

        .ql-toolbar {
            background: #eaecec;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
        }

        .ql-container {
            min-height: 10em;
            color: #000000;
            
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            background: #fefcfc;

            overflow-y: auto;
        }
        
        /*
        .ql-toolbar {
            background-color: #4E5F78;
            border-top-right-radius: 0.25rem;
            border-top-left-radius: 0.25rem;

            padding: 4px 6px !important;
            border: none !important;

            .ql-header {
                color: #ffffff !important;
            }

            .ql-header.ql-expanded {
                color: #000000 !important;
            }

            .ql-stroke,
            .ql-fill {
                stroke: #ffffff;
            }
        }

        .ql-container {
            color: #000000;
            background-color: #ffffff;
            border: none !important;
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;

            .ql-editor {
                padding: 8px 12px;
                box-shadow: inset 0 0 3px;

                min-height: 130px;
            }
        }
        */
    }

    .list-installment_data {
        padding: 0 10px !important;
    }

    .list-keywords {
        padding: 0 10px !important;

        height: 100%;

        :has(> div ul) {
            background-color: #00FF00;
        }
    }

    .padding-0-0 {
        padding: 0 0 !important;
    }

    .MuiDataGrid-root,
    .MuiDataGrid-cell {
        border: 0 !important;
        padding: 0 2px !important;
    }

    .MuiDataGrid-cell,
    .MuiDataGrid-columnHeaders {
        border-bottom: solid .5px #EBEBEB !important;
    }

    .MuiDataGrid-columnSeparator {
        color: #EBEBEB !important;
    }

    .MuiDataGrid-footerContainer {
        border-top: solid .5px #EBEBEB !important;
    }

    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows {
        margin: 0
    }

    .MuiDataGrid-toolbarContainer {
        button {
            color: white;
        }
    }

    @media (max-width: 1370px) {

        *,
        *::before,
        *::after {
            font-size: .8rem;
        }
    }



    .treeview {
        padding-top: 3px;

        display: block;
        background-color: #FFFFFF;
        border-radius: 0.25rem;

        flex-grow: 1;
        overflow-y: 'auto';
    }
    .treeview-root {
        padding: 0.075rem 0.375rem;
    }
    .treeview-group {
        color: #000000;
        font-weight: 600;

        span {
            display: block;
            padding: 0.25rem 0.375rem;
        }

        .treeview-item {
            margin-left: 10px;
            font-weight: normal;

            span {
                display: block;
                padding: 0.25rem 0.375rem ;
                cursor: pointer;
                border-radius: 0.25rem;

                &:hover {
                    background-color: #4E5F78;
                    color: #ffffff;
                }
            }
        }
    }

    .treeview-item {
        color: #000000;

        span {
            display: block;
            padding: 0.25rem 0.375rem ;
            cursor: pointer;
            border-radius: 0.25rem;

            &:hover {
                background-color: #4E5F78;
                color: #ffffff;
            }
        }
    }

    .rotating {
        svg,img {
            animation: loading 2s linear infinite;

            @keyframes loading {
                0% {
                    transform: rotate(0);
                }

                100% {
                    transform: rotate(360deg);
                }
            }

        }
    }


    .climate-location {
        span {
            font-size: 1rem;
            text-transform: uppercase;

        }
    }

    .climate-current-information {
        span {
            font-size: 1.2rem;
            color: #FFFFFF;
        }
    }