/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  text-rendering: optimizeLegibility;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

.MuiButtonBase-root {
  border-radius: 32px !important;
}

li {
  button {
    border-radius: 0 !important;
  }
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
  }
}

.SnackbarContainer-bottom .SnackbarContainer-center .SnackbarContainer-root {
  z-index: 10;
}

.MuiButton-outlinedPrimary {
  background-color: #FFFFFF !important;
}

.MuiMenuItem-root {
  border-radius: 0 !important;
}

.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
  color: #0000;
}

.was-validated .form-check-input:invalid~.form-check-label {
  color: #000;
}

.form-control, .form-select {
  font-size: .9rem;
}

.card-body {

  table {
  
    th,tr {
      text-align: center;
    }
  }
}

.table-responsive {
  text-align: center;
}

.partner.list-group {
  .list-group-item {
    background-color: var(--background-color);
    color: #FFFFFF;
    border-bottom: solid .1px #EBEBEB;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    align-items: center !important;
  }
}

.MuiMobileStepper-root {
  background: none !important;
  min-width: 500px;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  box-shadow: inset 0 0 0 1000px var(--background-color) !important;
  -webkit-tap-highlight-color: var(--background-color) !important;
  background-color: var(--background-color)!important;
}

.MuiButton-containedSuccess {
  color: #FFFFFF !important;
  background-color: #4cff00 !important;
}

.MuiSvgIcon-colorSuccess {
  color: #4cff00 !important;
}

th, .MuiDataGrid-columnHeaderTitle {
  text-transform: uppercase !important;
}

#simple-tab-0, #simple-tab-1, #simple-tab-2, #simple-tab-3 {
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
}


#simple-tabpanel-0, #simple-tabpanel-1, #simple-tabpanel-2, #simple-tabpanel-3 {
  .MuiBox-root {
    padding: 0
  }
}