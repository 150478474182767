.imageWrapper {
  transition: all ease-in-out .5s;
  display: flex;
  justify-content: center;
  height: 180px;

  img {
    width: 100%;
    max-height: 100px;
    object-fit: contain;
    margin: auto;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

  }
  &.vesselImage {
    height: 110px;
  }
  &.vesselImagePlaceholder {
    min-height: 100px;
    min-width: 108px;
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;

    .svg {
      margin-top: 23px;
      border: solid 3px #DADADA;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      display: flex;
      padding: 15px;
      justify-content: center;
      background-color: #DADADA;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

    }

  }

}

.imageControls {
  label {
    margin: 0;
    padding: 5px;
    width: fit-content;
  }
}