.sideMenu {
  li {

    &.active {

      p {
        background-color: #EBEBEB;
      }
    }

    p {
      transition: all ease-in-out .2s;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
}

.imageWrapper {
  transition: all ease-in-out .5s;
  display: flex;
  justify-content: center;
  height: 120px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    border-radius: 50%;

  }

}

.imageControls {
  label {
    margin: 0;
    padding: 5px;
    width: fit-content;
  }
}
