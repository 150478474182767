.subReasonModal {
  height: 650px;
  width: inherit;

  .infoTitle {
    color: var(--primary);
  }

  .info {
    color: #868585;
  }

  h5 {
    color: var(--primary);
  }

  .newDescription {
    min-width: 475px;
  }

  .switch {


    label {
      margin-right: 0;
      min-width: 120px;
    }
  }

}


.selectedReservation {
  margin-left: 160px;
}


.hourWrapper {
  transition: all ease-in-out .2s;

  .grayLine {
    background-color: darken(#586A88, 10%);
    transition: all ease-in-out .2s;
} 



  .hour {
    padding: 10px 6px;
    width: stretch;
    text-align: center;
    vertical-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    
    &.selected {
      font-weight: bolder;
      color: #FFFFFF;
      background-color: var(--primary);
      border-radius: 6px;
    }

    &.unavailable {
      color: red;
      font-weight: bolder;
    }
    
  
    &:first-child {
      padding-left: 5px;
    }
  
    &:last-child {
      padding-right: 5px;
    }
  }
}


.selectArrive {
  margin-top: 150px !important;
}

.selectDeparture {
  margin-top: 30px !important;
}

.selectDepartureAfternoon {
  margin-top: 40px !important;
}
