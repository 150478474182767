.container {
    .logoWrapper {
      background-color: #000000;
      display: flex;
      flex-direction: column;
      align-items: center;  
      justify-content: flex-start;
      height: 120px;
      margin-bottom: 80px;
    }
    .vesselName {

      h2 {
        margin-bottom: 0;
      }

      .theftIndicator {
        p {
          width: 118px;
          background-color: red;
          border-radius: 4px;
          padding: 2px 5px;
        }
      }
      .financialIndicator {
        
        p {
          width: 118px;
          background-color: yellow;
          border-radius: 4px;
          padding: 2px 5px;
          color: #000000;

        }
      }
    }

    .vesselInfo {
      background-color: #464E5C;
      height: max-content;
      margin: 0 15px;
      border-radius: 6px;
      margin-bottom: 25px;

      p {
        margin-bottom: 5px;
      }
      .header {
        border-bottom: solid 1px var(--background-color);

        h4 {
          font-size: calc(1.275rem + 0.3vw);
          border-bottom: none;
          margin-bottom: 0px;
        }
      }

      h4 {
        padding: 10px;
        border-bottom: solid 1px var(--background-color);
      }

      h5 {
        font-size: 1.1rem;
      }
      section {
        padding: 10px;
      }

      .owners {
        border-bottom: solid 1px #EBEBEB;

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
    form {
      min-width: 205px;
    }
}

.imageWrapper {
  transition: all ease-in-out .5s;
  display: flex;
  justify-content: center;
  height: 120px;
  margin-left: 15px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    border-radius: 50%;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
}

.theftIndicator {
        
  p {
    background-color: red;
    border-radius: 4px;
    padding: 2px 5px;
    margin-right: 15px;

  }
}
.financialIndicator {
  
  p {
    background-color: yellow;
    border-radius: 4px;
    padding: 2px 5px;
    color: #000000;

  }
}

.enrollment {
  font-size: 1rem;
}


@keyframes in {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes out {
  0% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeIn {
  animation: in 0.5s ease 0s normal forwards 1;
  -webkit-animation: in 0.5s ease 0s normal forwards 1;
}

.fadeOut {
  -webkit-animation: out 0.5s ease 0s normal forwards 1;
  animation: out 0.5s ease 0s normal forwards 1;
  display: none !important;
}

.arrow {
  position: relative;
  cursor: pointer;
  transition: all ease-in-out .2s !important;
  font-size: 2rem !important;
  top: 8px;
  right: 10px;

  &.rotate {
    transform: rotate(90deg);
  }
}

.controls {
  svg {
    font-size: 9rem;
  }
}