.imageButton {
  border: none;
  position: relative;
  height: max-content;

  &.rounded {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    padding: 0;
  }
  &.noBackground {
    background: none !important;
    height: 120px;
    width: 120px;
    margin: 0 15px;
  }
}
.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalImage {
  height: 90%;
  object-fit: contain;
  position: relative;
  top: 80px;
}
.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 70%;
  top: 50%;
  transform: translateY(-70%);
  position: absolute;
}
.modalHeader {
  height:60px;
  margin-bottom: 50px;
  margin-top: 10px;
  position: relative;

  button {
    color: #fff;
  }
}

.theftIndicator {
  background-color: red;
  opacity: .6;
  color: #FFFFFF;
  width: 100%;
  position: relative;
  bottom: 50%;
  font-weight: bold;
}

.financialIndicator {
  background-color: yellow;
  opacity: .6;
  color: #000000;
  width: 100%;
  position: relative;
  bottom: 40%;
  font-weight: bold;
}
